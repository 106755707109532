const config = {
  apiGateway: {
    REGION: 'us-west-2',
    INTERNAL_API_URL: 'https://mn0bpgdgwl.execute-api.us-west-2.amazonaws.com/prod',
    TEAM_API_URL: 'https://b60pxir6b3.execute-api.us-west-2.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'taxonomy-teams-1.10.2',
    HOSTNAME: 'https://teams-service.staging.newmont.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.staging.newmont.forwoodsafety.com',
    WEBSOCKET: 'wss://qie4vjq8uj.execute-api.us-west-2.amazonaws.com/staging'
  },
};

export default config;
